import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class PaginaProdusSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
    }
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  render() {
    const sliderSettings = {
      adaptiveHeight: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
    }
    const { productGallery } = this.props
    return (
      <>
        <Slider
          {...sliderSettings}
          className="primary"
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
        >
          {productGallery?.data.map((item, id) => {
            const currentImgUrl = `${process.env.STRAPI_BACKEND}${item?.attributes?.url}`
            return (
              <div className="section-full box-about-list" key={id}>
                <div className="row spno">
                  <img src={currentImgUrl} alt={`${item?.attributes?.alternativeText}`} className="img-cover" />
                </div>
              </div>
            )
          })}
        </Slider>
      </>
    )
  }
}
export default PaginaProdusSlider
