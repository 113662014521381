import React from "react"
import PaginaProdusSlider from "../../markup/element/th-sliders/thPaginaProdusSlider"
import "./pagina-produs.css"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


const Produs = props => {
  const { solutionInfo } = props

  console.log(solutionInfo)
  return (
    <div className="page-content bg-white th-page-wrapper th-products-page">
      <div
        className="section-full content-inner bg-white wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.4s"
      >
        <div className="container">
          <div className="on-show-slider">
            <PaginaProdusSlider productGallery={solutionInfo?.Gallery} />
          </div>
        </div>
      </div>

      <div className="produs-content-wrapper">
        {/* <span
          className="produs-content__solutia"
          style={{ color: "var(--th-secondary-color)" }}
        >
          LOREM IPSUM (SOLUTIA)
        </span> */}
        <h3 className="produs-content__title" style={{marginTop: "25px", marginBottom: "35px"}}>{solutionInfo?.Name}</h3>

        <p className="produs-content__description">
          {/* {solutionInfo?.Description} */}
          <ReactMarkdown children={solutionInfo?.Description} remarkPlugins={[remarkGfm]} />
        </p>

      </div>
    </div>
  )
}

export default Produs
