import React from "react"
import Layout from "../markup/layout/layout"
import Seo from "../markup/layout/seo"
import Produs from "../components/Pagina-Produs/Produs"
import { PageBanner } from "../markup/layout/PageBanner"

function produs() {
  const pageTitle = "Pagina Produs"
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        pageTitle={pageTitle}
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "Solutii", slug: "/solutii" },
          hasParent: true,
        }}
      />
      <Produs />
    </Layout>
  )
}

export default produs
